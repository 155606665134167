<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        Edit Note - {{ noteType }}
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea outlined v-model="note" label="Note" rows="3" auto-grow></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="saveNote">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default {
  props: {
    value: Boolean,
    userEmail: String,
    noteType: String,
    initialNote: String,
  },
  data() {
    return {
      dialog: false,
      note: "", // Initial empty string
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
      if (newVal) {
        this.fetchNoteForUser(this.userEmail, this.noteType); // Fetch the note when dialog opens
      }
    },
    userEmail(newVal) {
      if (this.dialog) {
        this.fetchNoteForUser(newVal, this.noteType); // React to userEmail change
      }
    },
    noteType(newVal) {
      if (this.dialog) {
        this.fetchNoteForUser(this.userEmail, newVal); // React to noteType change
      }
    },
  },
  methods: {
    async fetchNoteForUser(email, noteType) {
      const db = getFirestore();
      const collectionsToCheck = ['individuals', 'trusts', 'companies'];
      let noteFound = false;

      for (const collectionName of collectionsToCheck) {
        const collectionRef = collection(db, collectionName);
        const q = query(collectionRef, where('email', '==', email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const noteFieldName = `${noteType}Notes`; // Construct field name
          if (userData[noteFieldName]) {
            this.note = userData[noteFieldName]; // Update the note
            noteFound = true;
            break; // Stop if we found the note
          }
        }
      }

      if (!noteFound) {
        this.note = 'No note found'; // Handle case where no note is found
      }
    },
    saveNote() {
      this.$emit("note-updated", {
        userEmail: this.userEmail,
        noteType: this.noteType,
        newNote: this.note,
      });
      this.close();
    },
    close() {
      this.$emit("input", false); // Close dialog
    },
  },
  mounted() {
    // You might want to fetch the note when the component mounts, but only if `value` (dialog visibility) is true
    if (this.value) {
      this.fetchNoteForUser(this.userEmail, this.noteType);
    }
  },
};
</script>
