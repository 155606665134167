<template>
  <div>
    <v-overlay :value="loading" style="z-index: 999">
      <span class="loading-text">Please wait...</span>
      <div></div>
      <v-progress-circular :size="100" color="#008080" indeterminate></v-progress-circular>
    </v-overlay>
    <v-alert
      v-model="showAlert"
      :type="alertType"
      transition="scale-transition"
      dismissible
      class="top-center-alert"
      v-if="alertMessage"
    >
      {{ alertMessage }}
    </v-alert>
    <v-row class="user-management" justify="center">
      <v-col>
        <v-card rounded class="white-card full-width pb-8">
          <v-row>
            <h3 class="mx-3">User Management</h3>
            <v-text-field
              style="max-width: 30%; margin-left: 50%"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Name or Email"
              dense
              outlined
              rounded
              solo
              flat
              small
            ></v-text-field>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            show-expand
            class="elevation-1 my-custom-table"
            item-key="id"
            single-expand
          >
            <template v-slot:item.registrationDate="{ item }">
              {{ formatRegistrationDate(item.registrationDate) }}
            </template>
            <template v-slot:item.appId="{ item }">
              {{ item.appId }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="viewUserInfo(item)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="openEditUserModal(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="openDeleteModal(item.email)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.progress="{ item }">
              {{
                item.progress ? item.progress.toFixed(2) + "%" : "Calculating..."
              }}
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-select
                class="select-state"
                :items="availableStates"
                v-model="item.state"
                @change="updateUserState(item)"
                :menu-props="{ maxHeight: '200' }"
                dense
                outlined
                rounded
                solo
                flat
                small
              ></v-select>
            </template>
            <template v-slot:[`item.role`]="{ item }">
              <v-select
                class="select-role"
                :items="availableRoles"
                v-model="item.role"
                @change="updateUserRole(item)"
                :menu-props="{ maxHeight: '200' }"
                dense
                outlined
                rounded
                solo
                flat
                small
              ></v-select>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div style="width: 100%">
                  <v-data-table
                    :headers="TrustDetails"
                    :items="[item]"
                    hide-default-footer
                    class="sub-table"
                  >
                    <template v-slot:item.registrationDate="{ item }">
                      {{ formatRegistrationDate(item.registrationDate) }}
                    </template>
                    <template v-slot:item.fullAddress="{ item }">
                      {{ fullAddress(item) }}
                    </template>
                  </v-data-table>
                  <v-data-table
                    :headers="expandedHeaders"
                    :items="[item]"
                    show-expand
                    hide-default-footer
                    class="sub-table"
                  >
                    <template v-slot:item.fullAddress="{ item }">
                      {{ fullAddress(item) }}
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <div style="width: 100%">
                          <v-data-table
                            :headers="fileDetailHeaders"
                            :items="item.fileDetails"
                            hide-default-footer
                            class="sub-table"
                          >
                            <template v-slot:item.linkToFile="{ item }">
                              <div v-if="item.linkToFile">
                                <a :href="item.linkToFile" target="_blank">
                                  View File
                                </a>
                              </div>
                              <div v-else></div>
                            </template>
                            <template v-slot:item.status="{ item }">
                              <v-select
                                :items="['Approved', 'Declined', 'Under Review']"
                                v-model="item.status"
                                @change="handleDocumentStatusChange(item)"
                                dense
                                outlined
                                class="mt-5"
                                solo
                                style="width: 40%"
                                rounded
                                flat
                                small
                              ></v-select>
                            </template>
                            <template v-slot:item.actionRequest="{ item }">
                              <v-btn
                                small
                                @click="openEmailDialog(item.email, item.description)"
                                ><v-icon>mdi-email-fast</v-icon></v-btn
                              >
                            </template>
                            <template v-slot:item.notes="{ item }">
                              <div>
                                {{ item.notes || "No notes available" }}
                                <v-icon
                                  small
                                  @click="
                                    openNoteEditor(
                                      item.email,
                                      item.description,
                                      item[
                                        `${item.description.replace(/\s+/g, '')}Notes`
                                      ]
                                    )
                                  "
                                  >mdi-pencil</v-icon
                                >
                              </div>
                            </template>
                          </v-data-table>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <disconnect-referral
      ref="disconnectReferral"
      :user-name="selectedUserName"
      :user-email="selectedUserEmail"
      @disconnected="fetchItems"
    ></disconnect-referral>
    <view-user-modal ref="viewUserModal" :user-details="selectedUser">
    </view-user-modal>
    <edit-user-modal ref="editUserModal" @update-user="handleUpdateUser">
    </edit-user-modal>
    <note-editor
      v-model="isNoteEditorOpen"
      :userEmail="selectedUserEmail"
      :noteType="selectedNoteType"
      :initialNote="selectedNote"
      @note-updated="handleNoteUpdate"
    ></note-editor>
    <delete-user-modal
      ref="deleteUserModal"
      @userDeleted="fetchItems"
      @delete-user="handleDeleteUser"
    ></delete-user-modal>
    <v-dialog v-model="isEmailDialogOpen" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Send Email</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="emailForm">
            <v-text-field
              v-model="emailMessage"
              label="Message"
              required
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isEmailDialogOpen = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="sendEmail">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import DisconnectReferral from "@/components/DisconnectReferral.vue";
import NoteEditor from "@/components/NoteEditor.vue";
import EditUserModal from "@/components/EditUserModal.vue";
import DeleteUserModal from "@/components/DeleteUserModal.vue";
import ViewUserModal from "@/components/ViewUserModal.vue";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

export default {
  components: {
    EditUserModal,
    DeleteUserModal,
    NoteEditor,
    DisconnectReferral,
    ViewUserModal,
  },
  data() {
    return {
      search: "",
      items: [],
      selectedUser: null,
      selectedUserName: "",
      isNoteEditorOpen: false,
      selectedUserEmail: "",
      selectedNoteType: "",
      selectedNote: "",
      availableStates: [
        "Approved",
        "Declined",
        "In Progress",
        "Abandoned",
        "Awaiting Documents",
      ],
      availableRoles: ["Admin", "Trust"],
      headers: [
        { text: "Registration Date", value: "registrationDate" },
        { text: "Application ID", value: "appId" },
        { text: "Type", value: "role" },
        { text: "Trust Name", value: "TrustName" },
        { text: "Name", value: "name" },
        { text: "Last Name", value: "lastName" },
        { text: "Application Progress", value: "progress" },
        { text: "State", value: "state" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      TrustDetails: [
        { text: "Registration Date", value: "registrationDate" },
        { text: "Type", value: "role" },
        { text: "Trust Name", value: "TrustName" },
        { text: "Trust Reg No", value: "trustRegNumber" },
        { text: "Trustee Count", value: "trusteeCount" },
      ],
      expandedHeaders: [
        { text: "Name", value: "name" },
        { text: "Surname", value: "lastName" },
        { text: "ID Number", value: "idNumber" },
        { text: "Passport No", value: "passportNumber" },
        { text: "Email", value: "email" },
        { text: "Ovex Email", value: "OvexEmail" },
        { text: "Phone Number", value: "PhoneNumber" },
        { text: "Address", value: "fullAddress" },
      ],
      fileDetailHeaders: [
        { text: "Description", value: "description" },
        { text: "Link to File", value: "linkToFile" },
        { text: "Required", value: "required" },
        { text: "Status", value: "status" },
        { text: "Action Request", value: "actionRequest" },
        { text: "Notes", value: "notes" },
      ],
      loading: false,
      showAlert: false,
      alertMessage: '',
      alertType: 'success',
      isEmailDialogOpen: false,
      emailMessage: '',
      selectedEmail: '',
      selectedDescription: '',
    };
  },
  computed: {
    filteredItems() {
      if (!this.search) return this.items;
      return this.items.filter((item) => {
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    fullAddress() {
      return (item) => {
        let addressParts = [];

        if (item.streetNumber) {
          addressParts.push(item.streetNumber);
        }
        if (item.streetName) {
          addressParts.push(item.streetName);
        }
        if (item.suburb) {
          addressParts.push(item.suburb);
        }
        if (item.city) {
          addressParts.push(item.city);
        }
        if (item.provinceState) {
          addressParts.push(item.provinceState);
        }
        if (item.postalCode) {
          addressParts.push(item.postalCode);
        }
        if (item.country) {
          addressParts.push(item.country);
        }

        return addressParts.join(", ");
      };
    },
  },
  methods: {
    async handleDocumentStatusChange(item) {
      const db = getFirestore();
      if (!item || !item.uid || !item.description) {
        console.error("Invalid item data:", item);
        return;
      }

      const userRef = doc(db, "trusts", item.uid);
      const docStatusField = `docStatus.${item.description.replace(/\s+/g, '')}`;

      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          // Document exists, update the status
          await updateDoc(userRef, {
            [docStatusField]: item.status,
          });
        } else {
          // Document does not exist, create it with the status
          await setDoc(userRef, {
            [docStatusField]: item.status,
          });
        }
        console.log("Document status updated successfully");

        // Update the local state to reflect the change
        this.items = this.items.map((user) => {
          if (user.uid === item.uid) {
            const updatedFileDetails = user.fileDetails.map((fileDetail) => {
              if (fileDetail.description === item.description) {
                return {
                  ...fileDetail,
                  status: item.status,
                };
              }
              return fileDetail;
            });
            return {
              ...user,
              fileDetails: updatedFileDetails,
            };
          }
          return user;
        });
      } catch (error) {
        console.error("Error updating document status: ", error);
      }
    },
    openEditUserModal(item) {
      this.$refs.editUserModal.open(item);
    },
    transformFileDetails(user) {
      return [
        {
          description: "ID Card Front",
          linkToFile: user.idCardFrontUrl || "",
          required: "Always",
          status: user.docStatus && user.docStatus.IDCardFront || "Under Review",
          email: user.email,
          notes: user.IDCardFrontNotes || "",
          uid: user.uid,
        },
        {
          description: "ID Card Back",
          linkToFile: user.idCardBackUrl || "",
          required: "Always",
          status: user.docStatus && user.docStatus.IDCardBack || "Under Review",
          email: user.email,
          notes: user.IDCardBackNotes || "",
          uid: user.uid,
        },
        {
          description: "Bank Account Proof",
          linkToFile: user.bankAccountProofUrl || "",
          required: "Always",
          status: user.docStatus && user.docStatus.BankAccountProof || "Under Review",
          email: user.email,
          notes: user.BankAccountProofNotes || "",
          uid: user.uid,
        },
        {
          description: "Proof of Residence",
          linkToFile: user.proofOfResidenceUrl || "",
          required: "Always",
          status: user.docStatus && user.docStatus.ProofOfResidence || "Under Review",
          email: user.email,
          notes: user.ProofOfResidenceNotes || "",
          uid: user.uid,
        },
        {
          description: "Selfie",
          linkToFile: user.selfieUrl || "",
          required: "Always",
          status: user.docStatus && user.docStatus.Selfie || "Under Review",
          email: user.email,
          notes: user.SelfieNotes || "",
          uid: user.uid,
        },
      ];
    },
    openEmailDialog(email, description) {
      this.selectedEmail = email;
      this.emailMessage = `Please re-upload ${description}`;
      this.isEmailDialogOpen = true;
    },
    async sendEmail() {
      if (!this.$refs.emailForm.validate()) return;

      const emailParams = {
        to_email: this.selectedEmail,
        message: this.emailMessage,
      };

      try {
        await emailjs.send(
          "service_9esjl09",
          "template_nvcd0nk",
          emailParams,
          "mr1E1BaGMKZMEzWVu"
        );
        this.alertType = 'success';
        this.alertMessage = "Email sent successfully!";
      } catch (error) {
        console.error("Failed to send email:", error);
        this.alertType = 'error';
        this.alertMessage = "Failed to send email.";
      } finally {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
        this.isEmailDialogOpen = false;
      }
    },
    async updateStatus(item, detail, newStatus) {
      const userRef = doc(getFirestore(), "trusts", item.id);
      let updateData = {};
      updateData[`${detail}Status`] = newStatus;
      try {
        await updateDoc(userRef, updateData);
        item[`${detail}Status`] = newStatus;
      } catch (error) {
        console.error("Error updating status: ", error);
      }
    },
    disconnectReferralLinks(name, email) {
      this.selectedUserName = name;
      this.selectedUserEmail = email;
      this.$refs.disconnectReferral.open();
    },
    async fetchItems() {
      this.loading = true;
      const db = getFirestore();
      let items = [];
      let globalCount = 0;

      function calculateProgress(user) {
        const fields = [
          "TrustName",
          "idNumber",
          "passportNumber",
          "firstName",
          "lastName",
          "middleName",
          "city",
          "country",
          "postalCode",
          "suburb",
          "provinceState",
          "streetNumber",
          "streetName",
          "businessName",
          "employmentStatus",
          "sourceOfFunds",
          "sourceOfCrypto",
          "cellCode",
          "cellNumber",
          "phoneNumber",
          "idCardFrontUrl",
          "idCardBackUrl",
          "proofOfResidenceUrl",
          "bankAccountProofUrl",
          "OvexEmail",
          "selfieUrl",
        ];

        let filledFields = fields.filter(
          (field) => user[field] && user[field].trim() !== ""
        ).length;
        let totalFields = fields.length;

        return (filledFields / totalFields) * 100;
      }

      const dailyCountsCollection = collection(db, "dailyCounts");
      try {
        const dailyCountsSnapshot = await getDocs(dailyCountsCollection);
        dailyCountsSnapshot.forEach((doc) => {
          globalCount += doc.data().count;
        });
      } catch (error) {
        console.error("Error fetching daily counts:", error);
      }

      try {
        const querySnapshot = await getDocs(collection(db, "trusts"));
        querySnapshot.forEach((doc) => {
          let user = doc.data();
          let progress = calculateProgress(user);

          const itemData = {
            ...user,
            id: doc.id,
            appId: `${user.appId}-${String(globalCount).padStart(3, "0")}`,
            fileDetails: this.transformFileDetails(user),
            progress: progress,
          };
          items.push(itemData);
        });
      } catch (error) {
        console.error("Error fetching items from trusts:", error);
      }

      this.items = items;
      this.loading = false;
    },
    async viewUserInfo(item) {
      try {
        const db = getFirestore();
        const collectionRef = collection(db, "trusts");
        const q = query(collectionRef, where("email", "==", item.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            this.selectedUser = {
              ...doc.data(),
              type: "trusts",
              uid: doc.id,
            };
            this.$refs.viewUserModal.open();
          });
        } else {
          console.log("No user found with the email:", item.email);
        }
      } catch (error) {
        console.error(
          "Error fetching individual user with email:",
          item.email,
          error
        );
      }
    },
    async handleDeleteUser(email) {
      const firestore = getFirestore();
      const userRef = doc(firestore, "individuals", email);
      const companyRef = doc(firestore, "companies", email);
      const trustRef = doc(firestore, "trusts", email);

      try {
        const individualsnap = await getDoc(userRef);
        if (individualsnap.exists()) {
          console.log(
            "Email found in 'individuals' collection. Ready for deletion."
          );
        } else {
          console.log("Email not found in 'individuals' collection.");
        }
        const trustSnap = await getDoc(trustRef);
        if (trustSnap.exists()) {
          console.log("Email found in 'trusts'. Ready for deletion.");
        } else {
          console.log("Email not found in 'trusts' collection.");
        }

        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          console.log(
            "Email found in 'companies' collection. Ready for deletion."
          );
        } else {
          console.log("Email not found in 'companies' collection.");
        }

        this.fetchItems();
      } catch (error) {
        console.error("Error in processing user:", error);
      }
    },
    async updateUserState(item) {
      const itemRef = doc(getFirestore(), "trusts", item.uid);
      try {
        await updateDoc(itemRef, { state: item.state });
        console.log("User state updated successfully");
      } catch (error) {
        console.error("Error updating user state:", error);
      }
    },
    formatRegistrationDate(dateString) {
      const date = new Date(dateString);
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const timeString = date.toLocaleString("en-US", options);
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "long" });
      const year = date.getFullYear();
      return `${timeString} ${day} ${month} ${year}`;
    },
    openEditModal(item) {
      this.$refs.editUserModal.open(item);
    },
    openDeleteModal(userEmail) {
      this.$refs.deleteUserModal.open(userEmail);
    },
    openNoteEditor(email, noteType, note) {
      this.selectedUserEmail = email;
      this.selectedNoteType = noteType;
      this.selectedNote = note;
      this.isNoteEditorOpen = true;
    },
    async handleUpdateUser(updatedUser) {
      const userRef = doc(getFirestore(), "trusts", updatedUser.uid);
      try {
        await updateDoc(userRef, updatedUser);
        console.log("User updated successfully");
        this.fetchItems();
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
    async handleNoteUpdate({ userEmail, noteType, newNote }) {
      const trustsRef = collection(getFirestore(), "trusts");

      const q = query(trustsRef, where("email", "==", userEmail));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDocRef = querySnapshot.docs[0].ref;

        const formattedNoteType = noteType.replace(/\s+/g, "") + "Notes";

        const updatePayload = {};
        updatePayload[formattedNoteType] = newNote;

        await updateDoc(userDocRef, updatePayload);
        console.log("Note updated successfully.");

        this.fetchItems();
      } else {
        console.error("No matching document found for the provided email.");
      }
      this.isNoteEditorOpen = false;
    },
    async viewUserInfo(item) {
      try {
        const db = getFirestore();
        const collectionRef = collection(db, "trusts");
        const q = query(collectionRef, where("email", "==", item.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            this.selectedUser = {
              ...doc.data(),
              type: "trusts",
              uid: doc.id,
            };
            this.$refs.viewUserModal.open();
          });
        } else {
          console.log("No user found with the email:", item.email);
        }
      } catch (error) {
        console.error(
          "Error fetching individual user with email:",
          item.email,
          error
        );
      }
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style scoped>
.user-management .white-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.select-role {
  max-width: 200px;
  margin-top: 7%;
}
.my-custom-table >>> .v-data-table-header th {
  font-size: 18px;
  font-weight: bold;
  background-color: #7e196e;
  color: #ffffff !important;
}
.loading-text {
  color: #ffffff;
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
}
.select-state {
  max-width: 200px;
  margin-top: 7%;
}
.top-center-alert {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  z-index: 10000;
}
</style>
