<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="elevation-2 trades-card">
          <v-card-title class="card-title">Trades</v-card-title>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="table-header">SN</th>
                  <th class="table-header">From Currency</th>
                  <th class="table-header">To Currency</th>
                  <th class="table-header">From Amount</th>
                  <th class="table-header">To Amount</th>
                  <th class="table-header">Rate</th>
                  <th class="table-header">Status</th>
                  <th class="table-header">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(trade, index) in trades" :key="index">
                  <td>{{ trade.sn }}</td>
                  <td>{{ trade.from_currency }}</td>
                  <td>{{ trade.to_currency }}</td>
                  <td>{{ trade.from_amount }}</td>
                  <td>{{ trade.to_amount }}</td>
                  <td>{{ trade.rate }}</td>
                  <td>{{ trade.status }}</td>
                  <td>
                    <v-btn icon @click="openTradeDetails(trade)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Trade Details</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>SN</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.sn }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>From Currency</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.from_currency }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>To Currency</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.to_currency }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>From Amount</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.from_amount }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>To Amount</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.to_amount }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Rate</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.rate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.status }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Created At</v-list-item-title>
                <v-list-item-subtitle>{{ selectedTrade?.created_at }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#7e196e" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Trades",
  data() {
    return {
      trades: [],
      dialog: false,
      selectedTrade: null,
    };
  },
  async mounted() {
    await this.fetchTradesData();
  },
  methods: {
    async fetchTradesData() {
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/TradeHistory"
        );
        const data = await response.json();
        console.log(data); // Log the response data
        this.trades = data.otc_trades.map((trade, index) => ({
          sn: trade.sn,
          from_currency: trade.from_currency,
          to_currency: trade.to_currency,
          from_amount: trade.from_amount,
          to_amount: trade.to_amount,
          rate: trade.rate,
          status: trade.status,
          created_at: trade.created_at,
        })); // Store the data in the component's state
      } catch (error) {
        console.error("Error fetching Trades data:", error);
      }
    },
    openTradeDetails(trade) {
      this.selectedTrade = trade;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.trades-card {
  padding: 20px;
  background-color: #f9f9f9;
}
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #7e196e;
}
.v-btn {
  color: #7e196e;
}
.table-header {
  color: #7e196e;
  font-size: 1.1rem;
}
</style>
