<template>
  <v-container class="home-container" fluid>
    <v-row justify="center">
      <v-col cols="12" md="4" v-for="(collection, index) in collections" :key="index">
        <router-link :to="`/${collection.name.toLowerCase()}`" class="no-decoration">
          <v-card class="elevation-2 home-card">
            <div class="card-header">
              <v-card-title class="card-title">{{ collection.name }}</v-card-title>
            </div>
            <v-card-text class="card-count">
              {{ collection.count }}
            </v-card-text>
            <v-card-text class="card-footer">
              {{ collection.name }}
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
const db = getFirestore(firebaseApp);

export default {
  name: "Home",
  data() {
    return {
      collections: [
        { name: "Individuals", id: "individuals", count: 0 },
        { name: "Companies", id: "companies", count: 0 },
        { name: "Trusts", id: "trusts", count: 0 },
        { name: "New Registrations", id: "dailyCounts", count: 0 },
        { name: "Logins", id: "logins", count: 0 },
        { name: "Wallets", id: "wallets", count: 0 },
      ],
    };
  },
  async mounted() {
    await this.fetchCollectionCounts();
    await this.fetchTradesData();
    await this.fetchBrokerData();
  },
  methods: {
    async fetchCollectionCounts() {
      for (const collectionInfo of this.collections) {
        const collectionRef = collection(db, collectionInfo.id);
        const snapshot = await getDocs(collectionRef);
        collectionInfo.count = snapshot.size;
      }
      this.$forceUpdate(); // To ensure the reactivity updates the UI
    },
    async fetchTradesData() {
      try {
        const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/TradeHistory");
        const data = await response.json();
        
        // Assuming the API response structure
        const tradesDataCount = data.otc_trades.length;
        
        // Add a new card for the Trades data
        this.collections.push({
          name: "Trades",
          id: "trades",
          count: tradesDataCount
        });
        
        this.$forceUpdate(); // To ensure the reactivity updates the UI
      } catch (error) {
        console.error("Error fetching Trades data:", error);
      }
    },
    async fetchBrokerData() {
      try {
        const response = await fetch("https://hodl-vm.northeurope.cloudapp.azure.com/api/BrokerSelect");
        const data = await response.json();
        
        // Assuming the API response structure
        const brokerDataCount = data.length;
        
        // Add a new card for the Brokers data
        this.collections.push({
          name: "Brokers",
          id: "brokers",
          count: brokerDataCount
        });
        
        this.$forceUpdate(); // To ensure the reactivity updates the UI
      } catch (error) {
        console.error("Error fetching Broker data:", error);
      }
    },
  },
};
</script>

<style scoped>
.home-container {
  padding: 20px;
}
.home-card {
  margin-bottom: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.card-header {
  background-color: #BE1B74; /* Match the background color of the header */
  width: 100%;
  text-align: center;
}

.card-title {
  color: white; /* White text for the header */
  margin: 0;
}

.card-count {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.card-footer {
  text-align: center;
  color: #757575;
}

.no-decoration {
  text-decoration: none;
}
</style>
