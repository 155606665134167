<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="#BE1B74" dark @click="assignRole('Individual')">Individuals</v-btn>
          <v-btn color="#BE1B74" dark @click="assignRole('Company')">Companies</v-btn>
          <v-btn color="#BE1B74" dark @click="assignRole('Trust')">Trusts</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="#BE1B74" dark @click="triggerFileInput">Upload CSV</v-btn>
          <input ref="fileInput" type="file" accept=".csv" @change="loadCSV" style="display: none;" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="items" class="elevation-1"></v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="#BE1B74" dark @click="uploadUsers">Upload to Database</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="#BE1B74"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  data() {
    return {
      headers: [],
      items: [],
      collectionName: "", // To store the selected collection name
      loading: false, // To track loading state
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    loadCSV(event) {
      const file = event.target.files[0];
      if (file) {
        Papa.parse(file, {
          complete: this.updateTable,
          header: true,
        });
      }
    },
    updateTable(results) {
      const now = new Date();
      const registrationDate = now.toISOString().replace('T', ' ').substring(0, 23); // Format date

      const fields = results.meta.fields;
      this.headers = [{ text: "Registration Date", value: "registrationDate" }];
      fields.forEach((field) => {
        this.headers.push({ text: field, value: field });
      });
      this.headers.push({ text: "Role", value: "role" });

      this.items = results.data.filter(item => Object.keys(item).some(key => item[key].trim() !== "")).map((item) => ({
        ...item,
        role: "",
        password: this.generateRandomPassword(),
        email: item.email || "unknown",
        name: item.name || "unknown",
        lastName: item.lastName || "unknown",
        cellCode: item.cellCode || "unknown",
        cellNumber: item.cellNumber || "unknown",
        country: item.country || "unknown",
        idNumber: item.idNumber || "unknown",
        registrationDate, // Add registration date
      })); // Initialize role and password column
    },
    assignRole(role) {
      this.items = this.items.map((item) => ({ ...item, role }));
      if (role === 'Company') {
        this.collectionName = 'companies'; // Set the collection name to "companies" for Company role
      } else {
        this.collectionName = role.toLowerCase() + "s"; // Set the collection name based on the role
      }
    },
    generateRandomPassword(length = 12) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
      let password = "";
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    },
    async uploadUsers() {
      this.loading = true;
      try {
        const response = await fetch(
          "https://europe-west2-hodl-f3ae3.cloudfunctions.net/uploadBulkUsers", // Update with your cloud function URL
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              users: this.items,
              collectionName: this.collectionName,
            }),
          }
        );
        const data = await response.json();
        console.log("Upload response:", data);
        alert(`Upload successful: ${data.successCount} users uploaded.`);
        
        // Send each user to the API
        for (const user of this.items) {
          await this.sendToApi(user);
        }

        alert("All users have been processed.");
      } catch (error) {
        console.error("Error uploading users:", error);
        alert("Error uploading users. Check console for details.");
      } finally {
        this.loading = false;
      }
    },
    async sendToApi(userData) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const dataToSend = {
        companyName: userData.companyName || "unknown",
        companyVat: "",
        trustName: userData.TrustName || "unknown",
        trustRegNum: "",
        name: userData.name || "unknown",
        lastName: userData.lastName || "unknown",
        middleName: "",
        email: userData.email || "unknown",
        password: "",
        city: "",
        country: userData.country || "unknown",
        postalCode: "",
        suburb: "",
        streetNumber: "",
        streetName: "",
        PhoneNumber: userData.PhoneNumber || "unknown",
        emailVerified: "",
        idVerified: "",
        twoFactorAuthLink: "",
        twoFactorAuthStatus: "",
        identityNumber: "",
        idBookFront: "",
        driversFront: "",
        driversBack: "",
        idCardFront: "",
        idCardBack: "",
        passportFront: "",
        honeyCombResult: "",
        errorMessage: "",
        registrationDate: userData.registrationDate, // Include the registration date field
      };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(dataToSend),
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/RegistrationInsert",
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.text();
        console.log("API response:", result);
      } catch (error) {
        console.error("Error sending data to API:", error);
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  background-color: #be1b74;
  color: white;
  margin: 5px;
}

.v-data-table {
  margin-top: 20px;
}
</style>
