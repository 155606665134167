<template>
  <v-dialog v-model="dialog" :width="300">
    <v-card>
      <v-card-title>
        <v-icon v-if="isIndividual" left>mdi-account</v-icon>
        <!-- Individual Icon -->
        <v-icon v-else-if="isCompany" left>mdi-domain</v-icon>
        <!-- Company Icon -->
        <v-icon v-else left>mdi-account-group</v-icon>
        <!-- Trust Icon -->
        Edit {{ editedItem.type }}
      </v-card-title>
      <v-card-text>
        <div v-if="isIndividual">
          <div class="highlighted-name">{{ editedItem.name }}</div>
          <!-- Individual's Name -->
          <!-- Rounded text field for individual -->
          <v-text-field
            v-model="editedItem.email"
            label="New Email"
            rounded
            solo
            @input="formatEmail"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.OvexEmail"
            label="Ovex Email"
            rounded
            solo
            @input="formatEmail"
          ></v-text-field>
        </div>
        <div v-else>
          <!-- Rounded text field for company or trust -->
          <v-text-field
            v-model="editedItem.email"
            label="New Email"
            rounded
            solo
            @input="formatEmail"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.OvexEmail"
            label="Ovex Email"
            rounded
            solo
            @input="formatEmail"
          ></v-text-field>
        </div>
        <v-card-actions>
          <!-- Styled buttons -->
          <v-btn @click="save" rounded class="dark-blue-btn no-width mx-2"
            >Save</v-btn
          >
          <v-btn @click="close" rounded class="pink-btn no-width mx-2"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAuth, updateEmail, sendEmailVerification } from "firebase/auth";
import { doc, updateDoc, getFirestore, collection, query, where, getDocs } from "firebase/firestore";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
      editedItem: {},
      originalEmail: "",
    };
  },
  computed: {
    isIndividual() {
      return this.editedItem.type === "individual";
    },
    isCompany() {
      return this.editedItem.type === "company";
    },
    isTrust() {
      return this.editedItem.type === "trust";
    },
  },
  methods: {
    open(item) {
      console.log("Opening item:", item); // Log the item to verify its structure
      this.editedItem = { ...item };
      this.originalEmail = item.email;
      this.dialog = true;
    },
    async save() {
      this.dialog = false;
      const db = getFirestore();
      const collections = ["individuals", "companies", "trusts"];
      let foundDocRef = null;

      try {
        for (let collectionName of collections) {
          const q = query(collection(db, collectionName), where("email", "==", this.originalEmail));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              foundDocRef = doc.ref;
            });
            break;
          }
        }

        if (foundDocRef) {
          console.log("Found document ref:", foundDocRef);
          await updateDoc(foundDocRef, {
            email: this.editedItem.email,
            OvexEmail: this.editedItem.OvexEmail,
          });
          console.log("Email updated in Firestore successfully.");

          if (this.isIndividual && this.originalEmail !== this.editedItem.email) {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (currentUser) {
              // Send verification email
              await sendEmailVerification(currentUser);
              alert("Please check your new email for a verification link.");
            }
          }
        } else {
          console.error("Document not found in any collection.");
        }
      } catch (error) {
        console.error("Error updating document:", error);
      }

      this.$emit("update-item", this.editedItem, this.originalEmail); // Emit event to update Firestore
    },
    close() {
      this.dialog = false;
    },
    formatEmail(email) {
      email = email.trim().toLowerCase();
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        this.editedItem = { ...newVal };
        this.originalEmail = newVal.email;
      },
    },
  },
};
</script>

<style scoped>
  .highlighted-name {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
</style>
