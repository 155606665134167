<template>
  <v-app>
    <Navbar v-if="!routesToHide.includes($router.currentRoute.path)" />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  name: 'App',

  data: () => ({
    routesToHide: [
      "/",
    ],
  }),
  created() { },
  components: {
    Navbar,
  },
};
</script>

<style src='./assets/css/Global.css'></style>