<template>
    <div>
      <v-overlay :value="loading" style="z-index: 999">
        <span class="loading-text">Please wait...</span>
        <div></div>
        <v-progress-circular :size="100" color="#008080" indeterminate></v-progress-circular>
      </v-overlay>
      <v-row class="wallets-management" justify="center">
        <v-col>
          <v-card rounded class="white-card full-width pb-8">
            <v-row>
              <h3 class="mx-3">Wallets Management</h3>
            </v-row>
            <v-data-table
              :headers="userHeaders"
              :items="userList"
              show-expand
              class="elevation-1 my-custom-table mt-6"
              item-key="uid"
              single-expand
            >
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.email="{ item }">
                {{ item.email }}
              </template>
              <template v-slot:item.ovexEmail="{ item }">
                {{ item.ovexEmail }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div style="width: 100%">
                    <v-data-table
                      :headers="walletHeaders"
                      :items="item.wallets"
                      hide-default-footer
                      class="sub-table"
                    >
                      <template v-slot:item.currency_id="{ item }">
                        {{ item.currency_id.toUpperCase() }}
                      </template>
                      <template v-slot:item.name="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item.withdrawal_address="{ item }">
                        {{ item.withdrawal_address }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="viewWalletInfo(item)">mdi-eye</v-icon>
                        <v-icon small class="mr-2" @click="openEditWalletModal(item)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2" @click="openDeleteModal(item.walletId)">mdi-delete</v-icon>
                      </template>
                    </v-data-table>
                  </div>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  
      <v-dialog v-model="isEditDialogOpen" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Wallet</span>
          </v-card-title>
          <v-card-text>
            <!-- Edit form fields go here -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="saveWallet">Save</v-btn>
            <v-btn color="red darken-1" text @click="isEditDialogOpen = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="isDeleteDialogOpen" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Wallet</span>
          </v-card-title>
          <v-card-text>
            Are you sure you want to delete this wallet?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="deleteWallet">Delete</v-btn>
            <v-btn color="grey darken-1" text @click="isDeleteDialogOpen = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-alert v-model="showAlert" type="success" v-if="alertMessage" class="alert-top-center">
        {{ alertMessage }}
      </v-alert>
    </div>
  </template>
  
  <script>
  import { db } from '../firebaseConfig';
  import { getDoc, doc, collection, getDocs } from "firebase/firestore";
  
  export default {
    data() {
      return {
        search: '',
        userList: [],
        selectedUser: null,
        loading: false,
        isEditDialogOpen: false,
        isDeleteDialogOpen: false,
        alertMessage: '',
        showAlert: false,
        availableStatuses: ['Active', 'Inactive', 'Pending'],
        userHeaders: [
          { text: 'Name', value: 'name', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Ovex Email', value: 'ovexEmail', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        walletHeaders: [
          { text: 'Currency ID', value: 'currency_id', align: 'center' },
          { text: 'Name', value: 'name', align: 'center' },
          { text: 'Withdrawal Address', value: 'withdrawal_address', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },
    methods: {
      async fetchUsers() {
        this.loading = true;
        try {
          const collections = ['individuals', 'trusts', 'companies'];
          let allUsers = [];
          for (const collectionName of collections) {
            const usersSnapshot = await getDocs(collection(db, collectionName));
            const users = usersSnapshot.docs.map(async userDoc => {
              const userData = userDoc.data();
              const walletsDoc = await getDoc(doc(db, 'wallets', userDoc.id));
              if (walletsDoc.exists()) {
                const wallets = walletsDoc.data().wallet_data;
                return {
                  uid: userDoc.id,
                  name: `${userData.name} ${userData.lastName}`,
                  email: userData.email,
                  ovexEmail: userData.OvexEmail,
                  wallets: wallets
                };
              }
            });
            allUsers = [...allUsers, ...await Promise.all(users)];
          }
          this.userList = allUsers.filter(user => user !== undefined);
          console.log('Fetched Users with Wallets: ', this.userList);
        } catch (error) {
          console.error("Error fetching users: ", error);
        } finally {
          this.loading = false;
        }
      },
      viewWalletInfo(item) {
        // View wallet info logic
      },
      openEditWalletModal(item) {
        this.isEditDialogOpen = true;
      },
      openDeleteModal(walletId) {
        this.isDeleteDialogOpen = true;
      },
      saveWallet() {
        // Save wallet logic
        this.isEditDialogOpen = false;
      },
      deleteWallet() {
        // Delete wallet logic
        this.isDeleteDialogOpen = false;
      },
    },
    mounted() {
      this.fetchUsers();
    }
  };
  </script>
  
  <style scoped>
  .wallets-management .white-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .my-custom-table >>> .v-data-table-header th {
    font-size: 12px; /* Adjust the size as needed */
    background-color: #7e196e;
    color: #ffffff !important;
  }
  
  .loading-text {
    color: #ffffff; /* Change color as needed */
    margin-top: 20px; /* Adjust distance from spinner */
    font-size: 18px; /* Adjust font size as needed */
    text-align: center; /* Center the text */
  }
  
  .select-status {
    max-width: 200px;
    margin-top: 7%; /* Set the maximum width as per your design requirements */
  }
  </style>
  