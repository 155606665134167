import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebaseConfig"; // Ensure the path is correct
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Individual from "../views/Individual.vue";
import Trust from "../views/Trust.vue";
import Company from "../views/Company.vue";
import Brokers from "../views/Brokers.vue";
import Trades from "../views/Trades.vue";
import Bulkupload from "../views/Bulkupload.vue";
import Wallets from "../views/Wallets.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/wallets",
    name: "Wallets",
    component: Wallets,
    meta: { requiresAuth: true },
  },
  {
    path: "/individuals",
    name: "Individual",
    component: Individual,
    meta: { requiresAuth: true },
  },
  {
    path: "/Companies",
    name: "Company",
    component: Company,
    meta: { requiresAuth: true },
  },
  {
    path: "/trusts",
    name: "Trust",
    component: Trust,
    meta: { requiresAuth: true },
  },
  {
    path: "/brokers",
    name: "Brokers",
    component: Brokers,
    meta: { requiresAuth: true },
  },
  {
    path: "/trades",
    name: "Trades",
    component: Trades,
    meta: { requiresAuth: true },
  },
  {
    path: "/bulkupload",
    name: "Bulkupload",
    component: Bulkupload,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
