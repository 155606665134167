<template>
  <div>
    <v-dialog v-model="dialogVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Disconnect Referral Links </v-card-title>
        <v-card-text>
          Are you sure you want to disconnect referral links for
          <strong>{{ userEmail }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="disconnectLinks"
            >Disconnect</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    userEmail: String,
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      }
    }
  },
    methods: {
      close() {
      this.dialogVisible = false;
    },
      async disconnectLinks() {
        try {
          const myHeaders = new Headers();
          //myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
          myHeaders.append("Content-Type", "application/json");

          const raw = JSON.stringify({
            email: this.userEmail,
          });

          const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/BrokerDelete",
            requestOptions
          );
          const result = await response.text();
          console.log(result);
          this.$emit("disconnected");
          console.log(this.userEmail);
        } catch (error) {
          console.error("error", error);
          console.log(this.userEmail);
        }
        this.close();
      },
    },
  };
</script>
