<template>
  <div>
    <v-navigation-drawer
      v-if="showNavbar"
      style="z-index: 1;"
      v-model="drawer"
      app
      dark
      elevation="0"
      class="navigation-drawer pa-4"
    >
      <v-row justify="center" class="my-12">
        <v-img src="../assets/images/Logo.png" contain height="70"></v-img>
      </v-row>
      <v-list dense style="margin-top: 70px;">
        <v-list-item
          :class="{ 'active-item': isActiveItem(item.route) }"
          v-for="(item, index) in drawerItems"
          :key="index"
          @click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon class="v-list-item-title">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="v-list-item-title">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon class="v-list-item-title">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="v-list-item-title"
            >Logout</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="showNavbar"
      style="background-color: rgb(255, 255, 255);"
      app
      flat
    >
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-btn
          icon="$vuetify"
          rounded
          style="background-color: #be1b74; height: 35px; width: 36px;"
          ><v-icon color="white">mdi-menu</v-icon></v-btn
        >
      </v-app-bar-nav-icon>
      <v-row justify="center" class="my-1" style="margin-right: 35px;">
        <h2 style="color: rgb(0, 0, 0);" class="text">HODL ADMIN DASHBOARD</h2>
      </v-row>
      <!-- Other app bar components -->
    </v-app-bar>
  </div>
</template>

<script>
  import { getAuth, signOut } from "firebase/auth";
  import { firebaseApp } from "../firebaseConfig";

  export default {
    data() {
      return {
        drawer: true,
        drawerItems: [
          { title: "Home", icon: "mdi-account-details", route: "/home" },
          { title: "Wallets", icon: "mdi-wallet", route: "/wallets" },
          { title: "Individual", icon: "mdi-account", route: "/individuals" },
          { title: "Company", icon: "mdi-account-group", route: "/companies" },
          { title: "Trust", icon: "mdi-account-multiple", route: "/trusts" },
          {
            title: "Broker requests",
            icon: "mdi-account-switch",
            route: "/brokers",
          },
          {
            title: "Trades",
            icon: "mdi-swap-horizontal-circle",
            route: "/trades",
          },
      //    {
        //    title: "Bulk up load",
          // icon: "mdi-account-plus",
        //   route: "/bulkupload",
        //  },
        ],
        showNavbar: true,
      };
    },
    watch: {
      $route(to) {
        this.showNavbar = to.path !== "/";
      },
    },
    created() {
      this.showNavbar = this.$route.path !== "/";
    },
    methods: {
      isActiveItem(route) {
        return this.$route.path === route;
      },
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      navigate(route) {
        if (this.$router.currentRoute.path === route) {
          return;
        }
        this.$router.push(route);
      },
      logout() {
        const auth = getAuth(firebaseApp);
        signOut(auth)
          .then(() => {
            this.$router.push("/");
          })
          .catch((error) => {
            console.error("Sign out error", error);
          });
      },
    },
  };
</script>

<style scoped>
  .navigation-drawer::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .active-item .v-list-item-title {
    color: rgb(255, 255, 255);
  }

  .active-item {
    border-radius: 20px;
    background-color: #be1b74;
    color: white;
    font-family: "Black Han Sans", sans-serif;
  }
</style>
