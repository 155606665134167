<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-overlay :value="loading" style="z-index: 999">
      <span class="loading-text">Please wait...</span>
      <v-progress-circular :size="100" color="#008080" indeterminate>
      </v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">User Information</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <!-- Left Column for User Details -->
            <v-col cols="6">
              <div class="text-center">
                <v-img
                  :src="userDetails.selfieUrl"
                  aspect-ratio="1"
                  contain
                  class="mb-3" />
              </div>
              <div><strong>ID:</strong> {{ userDetails.idNumber }}</div>
              <div><strong>Name:</strong> {{ userDetails.name }}</div>
              <div><strong>Surname:</strong> {{ userDetails.lastName }}</div>
              <div><strong>Country:</strong> {{ userCountry.text }}</div>
            </v-col>
            <v-col cols="6">
              <!-- Other user details display -->
              <div v-if="honeyCombResult">
                <div>
                  <v-img
                    :src="
                      'data:image/png;base64,' +
                      honeyCombResult.homeAffairsPhoto.photo
                    "
                    aspect-ratio="1"
                    contain
                    class="mb-3" />
                </div>
                <div>
                  <strong>ID:</strong>
                  {{ honeyCombResult.homeAffairsPhoto.idNumber }}
                </div>
                <div>
                  <strong>Name:</strong>
                  {{ honeyCombResult.homeAffairsPhoto.name }}
                </div>
                <div>
                  <strong>Surname:</strong>
                  {{ honeyCombResult.homeAffairsPhoto.surname }}
                </div>
                <div>
                  <strong>Country:</strong>
                  {{ honeyCombResult.homeAffairsPhoto.birthPlaceCountryCode }}
                </div>
                <!-- Add more fields as needed -->
              </div>
              <div v-else>No HoneyCombResult data available</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="compareUserData">Compare</v-btn>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import countriesData from "@/assets/countries/countries.json";

  export default {
    props: {
      userDetails: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        dialog: false,
        honeyCombResult: null,
        loading: false,
        countries: countriesData.map((country) => ({
          text: country.name,
          value: country.code,
          imageUrl: country.image,
        })),
      };
    },
    computed: {
      userCountry() {
        const country = this.countries.find(
          (c) => c.value === this.userDetails.country
        );
        return country || { text: "Unknown", imageUrl: "" };
      },
    },
    methods: {
      open() {
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false; // Close the dialog
        this.honeyCombResult = null; // Clear the API call result
      },
      async compareUserData() {
        this.loading = true; // Start loading
        await this.fetchUserDataFromAPI();
        await this.sendToApi(this.userDetails);
        this.loading = false; // End loading
      },
      async fetchUserDataFromAPI() {
        const myHeaders = new Headers();
        myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
        const email = encodeURIComponent(this.userDetails.email);

        try {
          const response = await fetch(
            `https://corsproxy.io/?https://hodl-vm.northeurope.cloudapp.azure.com/api/registrationSelect?email=${email}`,
            {
              method: "GET",
              headers: myHeaders,
              redirect: "follow",
            }
          );
          const result = await response.json();

          // Optionally update userDetails with fetched data
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      },
      async sendToApi(userDetails) {
        const myHeaders = new Headers();
        //myHeaders.append("APIKey", "t3iKRnspNMAyNmEoJg6t");
        myHeaders.append("Content-Type", "application/json");

        const payload = {
          companyName: "",
          companyVat: "",
          name: "",
          lastName: "",
          middleName: "",
          email: userDetails.email,
          password: "",
          city: "",
          country: "",
          postalCode: "",
          suburb: "",
          streetNumber: "",
          streetName: "",
          phoneNumber: "",
          emailVerified: "",
          idVerified: "",
          twoFactorAuthLink: "",
          twoFactorAuthStatus: "",
          identityNumber: userDetails.idNumber,
          trustRegNum: "",
          trustName: "",
          idBookFront: "",
          driversFront: "",
          driversBack: "",
          idCardFront: "",
          idCardBack: "",
          passport: "",
          passportFront: "", // Added based on your initial structure
          honeyCombResult: "",
          errorMessage: "",
        };

        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/RegistrationUpdate",
            {
              method: "PUT",
              headers: myHeaders,
              body: JSON.stringify(payload),
              redirect: "follow",
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const text = await response.text(); // Use text() to read the response body as text

          // Check if the response body is not empty and is valid JSON
          if (text) {
            try {
              const result = JSON.parse(text); // Attempt to parse the text as JSON
              this.honeyCombResult = JSON.parse(result.honeyCombResult);
              
            } catch (jsonError) {
              console.error("Error parsing JSON from API:", jsonError);
              // Handle the JSON parsing error here (e.g., set an error message state variable)
            }
          } else {
          
            // Handle an empty response here
          }
        } catch (error) {
          console.error("Error sending data to API:", error);
          // Handle network error here (e.g., set an error message state variable)
        }
      },
      convertBase64ToImage() {
        const base64String = this.honeyCombResult.homeAffairsPhoto.photo;

        // Create an Image object
        const img = new Image();
        // Set the source of the image to the Base64 string
        img.src = base64String;
        // When the image has loaded, update the userDetails.photoOfSelf property with the Base64 string
        img.onload = () => {
          this.userDetails.photoOfSelf = base64String;
        };
      },
    },
  };
</script>
<style>
  .loading-text {
    color: #ffffff; /* Change color as needed */
    margin-top: 20px; /* Adjust distance from spinner */
    font-size: 18px; /* Adjust font size as needed */
    text-align: center; /* Center the text */
  }
</style>
