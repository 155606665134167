<template>
  <div>
    <v-overlay :value="loading" absolute>
      <span class="loading-text">Please wait...</span>
      <v-progress-circular
        :size="100"
        color="#008080"
        indeterminate></v-progress-circular>
    </v-overlay>

    <v-row class="user-management" justify="center">
      <v-col>
        <v-card rounded class="white-card full-width pb-8">
          <v-row>
            <h3 class="mx-3">Referral Management</h3>
            <v-text-field
              style="max-width: 30%; margin-left: 50%"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Name, Email, or Referred Email"
              dense
              outlined
              rounded
              solo
              flat
              small>
            </v-text-field>
          </v-row>
          <!-- Referral Management Table -->
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="5"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-1 my-custom-table"
            item-key="friendReferredId"
            dense
            :footer-props="{ 'items-per-page-options': [5, 10, 15, -1] }"
            @update:page="fetchItems"
            @update:items-per-page="fetchItems"
            @update:sort-by="fetchItems"
            @update:sort-desc="fetchItems">
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="openDisconnectDialog(item)"
                >mdi-link-off</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <disconnect-referral-dialog
        :is-visible.sync="showDisconnectDialog"
        :user-name="selectedUserName"
        :user-email="selectedUserEmail"
        @disconnected="fetchItems">
      </disconnect-referral-dialog>
    </v-row>
  </div>
</template>

<script>
  import DisconnectReferralDialog from "../components/DisconnectReferral.vue"

  export default {
    components: {
      'disconnect-referral-dialog': DisconnectReferralDialog,
    },
    data() {
      return {
        search: "",
        items: [],
        loading: false,
        selectedUserName: "",
        selectedUserEmail: "",
        showDisconnectDialog: false,
        headers: [
          { text: "Date", value: "date" },
          { text: "Email", value: "email" },
          { text: "ID Number", value: "idNumber" },
          { text: "Name", value: "name" },
          { text: "Surname", value: "surname" },
          { text: "Level", value: "level" },
          { text: "Link", value: "link" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        totalItems: 0,
      };
    },
    computed: {
      filteredItems() {
        return this.items;
      },
    },
    methods: {
      async fetchItems() {
        this.loading = true;
        const requestOptions = {
          method: "GET",
          headers: {
            // "APIKey": "t3iKRnspNMAyNmEoJg6t",
          },
          redirect: "follow",
        };

        try {
          const response = await fetch(
            "https://hodl-vm.northeurope.cloudapp.azure.com/api/BrokerSelect",
            requestOptions
          );
          if (!response.ok) throw new Error("Network response was not ok.");
          const data = await response.json();
          this.items = data.map((item) => ({
            date: item.date,
            email: item.email,
            idNumber: item.idNumber,
            name: item.name,
            surname: item.surname,
            level: item.level,
            status: item.status,
            link: item.link,
          }));
          this.totalItems = data.length;
        } catch (error) {
          console.error("Error fetching items:", error);
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      openDisconnectDialog(item) {
        console.log("Selected User Name: ", item.name);
        console.log("Selected User Eame: ", item.email); // Debugging line
        this.selectedUserName = item.name;
        this.selectedUserEmail = item.email;
        console.log(
          "Opening dialog with:",
          this.selectedUserName,
          this.selectedUserEmail
        );
        this.showDisconnectDialog = true;
      },
      closeDialog() {
        this.showDisconnectDialog = false;
      },
      confirmDisconnect() {
        this.closeDialog();
      },
    },
    created() {
      this.fetchItems();
    },
  };
</script>

<style scoped>
  .user-management .white-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
</style>

<style scoped>
  .select-role {
    max-width: 200px;
    margin-top: 7%;
  }
  .my-custom-table >>> .v-data-table-header th {
    font-size: 18px;
    font-weight: bold;
    background-color: #7e196e;
    color: #ffffff !important ;
  }
  .loading-text {
    color: #ffffff; /* Change color as needed */
    margin-top: 20px; /* Adjust distance from spinner */
    font-size: 18px; /* Adjust font size as needed */
    text-align: center; /* Center the text */
  }
  .select-state {
    max-width: 200px;
    margin-top: 7%; /* Set the maximum width as per your design requirements */
  }
</style>
