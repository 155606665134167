<template>
  <v-container class="fill-height-new flex no-overflow">
    <video autoplay muted loop class="video">
      <source src="../assets/images/Globe.mp4" type="video/mp4" />
    </video>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-row justify="center" class="my-12">
          <v-img src="../assets/images/Logo.png" contain height="90"></v-img>
        </v-row>
        <v-card class="elevation-12 login-card">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <h2 class="mb-4 white--text">Admin Login</h2>
            </v-col>
          </v-row>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="email"
                label="Email"
                type="email"
                prepend-inner-icon="mdi-email"
                outlined
                dense
                filled
                dark
                rounded
                class="mb-4"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                prepend-inner-icon="mdi-lock"
                outlined
                dark
                filled
                dense
                rounded
                class="mb-4"
                required
              ></v-text-field>
              <v-btn
                @click="login"
                solo
                dark
                dense
                block
                class="py-4 pink button"
                rounded
              >Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="background-overlay"></div>
  </v-container>
</template>

<script>
import { auth } from "../firebaseConfig.js";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      const collectionsToSearch = ["individuals", "companies", "trusts"];
      try {
        await setPersistence(auth, browserLocalPersistence);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const uid = userCredential.user.uid;

        let isAdmin = false;
        for (const collection of collectionsToSearch) {
          const userDocRef = doc(db, collection, uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().role === "Admin") {
            isAdmin = true;
            break;
          }
        }

        if (isAdmin) {
          if (this.$route.path !== "/home") {
            this.$router.push({ path: "/home" });
          }
        } else {
          await auth.signOut();
          alert(
            "You do not have the necessary permissions to access this application."
          );
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user && this.$route.path !== "/home") {
        this.$router.push({ path: "/home" });
      }
    });
  },
};
</script>

<style scoped>
.fill-height-new {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../assets/images/Home-Background.png") no-repeat center
    center;
  background-size: cover;
  position: relative;
}

.no-overflow {
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.v-card {
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.801);
}

.login-card {
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.v-card-title {
  flex-direction: column;
  padding-bottom: 0;
}

.auto {
  margin: auto !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 0.3) 100%
  );
  z-index: -1;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
</style>
