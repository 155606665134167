<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>Delete User</v-card-title>
      <v-card-text>
        Are you sure you want to delete or toggle the status of this user?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          class="dark-blue-btn no-width mx-2"
          @click="confirmDelete"
          >Delete</v-btn
        >
        <v-btn
          rounded
          class="blue-btn no-width mx-2"
          @click="toggleStatus"
        >
          {{ isDisabled ? 'Enable' : 'Disable' }} <!-- Conditional text based on isDisabled -->
        </v-btn>
        <v-btn rounded class="pink-btn no-width mx-2" @click="cancel"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      userEmail: String,
      isDisabled: Boolean,
    },
    data() {
      return {
        show: false,
      };
    },
    methods: {
      open(userEmail) {
        this.userEmail = userEmail; // Assign the passed email to userEmail
        this.show = true;
      },
      async confirmDelete() {
        try {
          const res = await fetch(
            `https://europe-west2-hodl-f3ae3.cloudfunctions.net/deleteUserAndData`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email: this.userEmail }),
            }
          );

          if (!res.ok) {
            throw new Error("Failed to delete user");
          }
          const responseBody = await res.json();
        
          this.$emit('userDeleted');
        } catch (error) {
          console.error("Error deleting user:", error);
        }
        this.show = false;
      },
      async toggleStatus() {
        try {
          const res = await fetch(
            `https://europe-west2-hodl-f3ae3.cloudfunctions.net/toggleUserStatus`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: this.userEmail,
                disable: !this.isDisabled,
              }),
            }
          );

          if (res.ok) {
            const responseBody = await res.json();
    
            this.isDisabled = !this.isDisabled;
            this.$emit('userStatusToggled', this.isDisabled);
          } else {
            throw new Error("Failed to toggle user status");
          }
        } catch (error) {
          console.error("Error toggling user status:", error);
        }
        this.show = false;
      },
      cancel() {
        this.show = false;
      },
    },
  };
</script>
