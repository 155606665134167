// import {initializeApp, auth} from "firebase/compat/app"
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyB2lvr5u-FZWcbBP3l2o5-FIbFd-PqfLj4",
  authDomain: "hodl-f3ae3.firebaseapp.com",
  projectId: "hodl-f3ae3",
  storageBucket: "hodl-f3ae3.appspot.com",
  messagingSenderId: "404522637093",
  appId: "1:404522637093:web:14ced59ddf5bdacb176ccc",
  measurementId: "G-RFE1FHXDRK",
};
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

